.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 1000ms ease-in-out;
    z-index: 20;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}

.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}

.ReactModal__Content {
  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 2rem);
  overflow-y: auto;
}